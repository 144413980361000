/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-search-text-highlight {
  background-color: #b400aa;
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-search-popover {
  padding: 0 8px;
}
.rpv-search-popover-input-counter {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  margin-bottom: 8px;
  position: relative;
  width: 180px;
}
.rpv-search-popover-input {
  border: none;
  padding: 4px;
  width: 100%;
}
.rpv-search-popover-counter {
  align-items: center;
  bottom: 0px;
  display: flex;
  padding-right: 4px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.rpv-search-popover-label {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}
.rpv-search-popover-label-checkbox {
  margin-right: 4px;
}
.rpv-search-popover-footer {
  align-items: center;
  display: flex;
}
.rpv-search-popover-footer-item {
  padding: 0px 4px;
}
.rpv-search-popover-footer-button {
  margin-left: auto;
}
